<template>
  <div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <h5 class="font-weight-bold">{{ $t("partner.stepFtpUser") }}:</h5>
      </div>
    </div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <p>{{ $t("partner.stepFtpUserIntro") }}</p>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("partner.stepFtpUserRoot") }}
      </label>
      <div class="col-lg-9 col-xl-6">
        <code>{{ root }}</code>
      </div>
    </div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <p>
          {{ $t("partner.stepFtpUserLink") }}
          <a :href="SERVER_CON" target="_blank"
            >{{ SERVER_CON }} <i class="fal fa-arrow-up-right-from-square"
          /></a>
        </p>
      </div>
    </div>

    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("client.storageType") }}
      </label>
      <div class="col-md-9 col-lg-6 col-xl-4">
        <v-select
          v-model="selectedStorageType"
          class="form-control"
          :items="storageTypes"
          :item-text="item => $t(item.label)"
          item-value="type"
          :menu-props="{ offsetY: true }"
          @change="selectStorageType"
        />
      </div>
    </div>

    <div
      v-for="(entry, key) in selectedStorageTypeData.config"
      :key="key"
      class="form-group row align-items-center"
    >
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t(entry.label) }} <span class="text-danger">*</span>
      </label>

      <div class="col-md-9 col-lg-6 col-xl-4">
        <div v-if="entry.type !== 'boolean'" class="input-group">
          <b-form-input
            v-if="entry.type === 'text'"
            v-model="storageData.config[key]"
            type="text"
            class="form-control"
            :disabled="storageConnectionCreated"
            :class="
              storageData.config[key] === undefined ||
              storageData.config[key] === ''
                ? 'is-invalid'
                : 'is-valid'
            "
          />
          <div
            v-if="entry.type === 'password'"
            class="input-group-prepend"
            @click="copyText(storageData.config[key])"
          >
            <span class="input-group-text">
              <i class="fal fa-copy cursor-pointer" />
            </span>
          </div>
          <b-form-input
            v-if="entry.type === 'password'"
            v-model="storageData.config[key]"
            :type="showPassword ? 'text' : 'password'"
            class="form-control"
            autocomplete="on"
            :disabled="storageConnectionCreated"
            :class="
              storageData.config[key] === undefined ||
              storageData.config[key] === ''
                ? 'is-invalid'
                : 'is-valid'
            "
          />
          <div
            v-if="entry.type === 'password'"
            class="input-group-append"
            @click="togglePassword()"
          >
            <span class="input-group-text">
              <i
                class="fal icon-lg cursor-pointer"
                :class="[showPassword ? 'fa-eye-slash' : 'fa-eye']"
              />
            </span>
          </div>
          <b-form-invalid-feedback
            v-if="
              storageData.config[key] === undefined ||
              storageData.config[key] === ''
            "
            class="ml-1"
          >
            {{ $t("validation.required.general", { label: $t(entry.label) }) }}
          </b-form-invalid-feedback>
        </div>
        <span v-if="entry.type === 'boolean'" class="switch switch-sm">
          <label>
            <input
              v-model="storageData.config[key]"
              type="checkbox"
              :disabled="storageConnectionCreated"
            />
            <span />
          </label>
        </span>
      </div>
    </div>

    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <button
          class="btn btn-primary"
          :class="{ 'spinner spinner-white spinner-right': isBusyFtp }"
          :disabled="isBusyFtp || storageConnectionCreated"
          @click="createStorageConnection"
        >
          {{ $t("client.stepTenantConnectionsAssign") }}
        </button>
      </div>
    </div>

    <div
      v-if="storageConnectionCreated"
      class="form-group row align-items-center mt-5"
    >
      <div class="col-xl-3">
        <span class="svg-icon svg-icon-success svg-icon-3x">
          <inline-svg src="media/svg/icons/Navigation/Double-check.svg" />
        </span>
      </div>
      <div class="col-lg-9 col-xl-6">
        <div class="">{{ $t("client.stepTenantConnectionsSuccess") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import TenantStorage from "@/components/Admins/TenantStorage/tenantStorage";

export default {
  components: {},
  props: ["uuid", "partnerData"],
  data() {
    return {
      SERVER_CON: process.env.VUE_APP_SERVER_CON,
      storageTypes: TenantStorage.getTypes(),
      storageData: {
        type: "ftp",
        config: {}
      },
      selectedStorageType: {},
      storageConnectionCreated: false,
      showPassword: false,
      isBusyFtp: false
    };
  },
  computed: {
    root: function () {
      let uuid = this.uuid ?? "UUID";
      return `external-storage/${uuid}`;
    },
    selectedStorageTypeData() {
      let returnData = {};
      this.storageTypes.forEach(entry => {
        if (entry.type === this.selectedStorageType) {
          returnData = entry;
        }
      });
      return returnData;
    }
  },
  mounted() {
    if (this.storageTypes.length > 0) {
      this.selectedStorageType = this.storageTypes[0].type;
      this.selectStorageType();
    }
  },
  methods: {
    selectStorageType() {
      this.storageData.type = this.selectedStorageTypeData.type;
      this.storageData.config = {};
      for (const config in this.selectedStorageTypeData.config) {
        if (config === "port") {
          this.storageData.config[config] = "21";
        } else if (config === "timeout") {
          this.storageData.config[config] = "120";
        }
        if (this.selectedStorageTypeData.config[config].type === "boolean") {
          this.storageData.config[config] = false;
        }
      }
      if (!this.storageData.config.root) this.storageData.config.root = "";
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    validateData() {
      for (const config in this.selectedStorageTypeData.config) {
        if (
          this.storageData.config[config] === undefined ||
          this.storageData.config[config] === ""
        ) {
          return false;
        }
      }
      return true;
    },
    createStorageConnection() {
      if (!this.validateData()) return;
      this.isBusyFtp = true;
      this.storageData.tenant_id = this.partnerData.tenant.id;
      if (
        this.storageData.config.root === undefined ||
        this.storageData.config.root === null
      ) {
        this.storageData.config.root = "";
      }

      TenantStorage.store(this.storageData)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("client.stepTenantStorageStorageAssigned")
          });
          this.storageConnectionCreated = true;
          this.isBusyFtp = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusyFtp = false;
        });
    },
    isReady() {
      if (!this.storageData.config.root) this.storageData.config.root = "";
      if (!this.storageConnectionCreated) {
        this.$toast.fire({
          icon: "warning",
          title: this.$t("client.stepTenantConnectionsWarning")
        });
      }
      return this.storageConnectionCreated;
    },
    copyText(text) {
      this.$copyText(text);
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied")
      });
    }
  }
};
</script>
