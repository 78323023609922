import ApiService from "@/core/services/api.service";
var url = process.env.VUE_APP_API_ADMIN;
ApiService.setHeader();
class TenantConnections {
  get(id) {
    return ApiService.get(url, "tenant_connections/" + id);
  }
  getAll(params) {
    var options = "?page=" + params.page + "&perPage=" + params.size;
    return ApiService.get(url, "tenant_connections" + options);
  }
  store(data) {
    return ApiService.post(url + "/tenant_connections", data);
  }
  update(id, data) {
    return ApiService.put(url + "/tenant_connections/" + id, data);
  }
  delete(id) {
    return ApiService.delete(url + "/tenant_connections/" + id);
  }
}
export default new TenantConnections();
