<template>
  <div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <h5 class="font-weight-bold">
          {{ $t("partner.stepAddPartnerEntry") }}:
        </h5>
      </div>
    </div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <p>{{ $t("partner.stepAddPartnerEntryIntro") }}</p>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("partner.partnerName") }}
      </label>
      <div class="col-md-9 col-lg-6 col-xl-4">
        <input
          v-model="$v.partner.$model"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': $v.partner.$error }"
          :readonly="partnerAdded"
        />
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label"></label>
      <div class="col-md-9 col-lg-6 col-xl-4">
        <button
          class="btn btn-primary"
          :class="{ 'spinner spinner-white spinner-right': isBusy }"
          :disabled="$v.partner.$invalid || isBusy || partnerAdded"
          @click="storePartner"
        >
          {{ $t("partner.addPartner") }}
        </button>
      </div>
    </div>
    <div v-if="partnerAdded" class="form-group row align-items-center mt-5">
      <div class="col-xl-3">
        <span class="svg-icon svg-icon-success svg-icon-5x">
          <inline-svg src="media/svg/icons/Navigation/Double-check.svg" />
        </span>
      </div>
      <div class="col-lg-9 col-xl-6">
        <div class="text-h5 mb-3">
          {{ $t("partner.addPartnerSuccessTitle") }}
        </div>
        <p class="mb-0">
          {{ $t("partner.addPartnerSuccessMessage", { name: partner }) }}
        </p>
      </div>
    </div>
    <div
      v-else-if="partnerError"
      class="form-group row align-items-center mt-5"
    >
      <div class="col-xl-3">
        <span class="svg-icon svg-icon-danger svg-icon-5x">
          <inline-svg src="media/svg/icons/Navigation/Close.svg" />
        </span>
      </div>
      <div class="col-lg-9 col-xl-6">
        <div class="text-h5 mb-3">{{ $t("partner.addPartnerErrorTitle") }}</div>
        <p class="mb-0">
          {{ $t("partner.addPartnerErrorMessage", { name: partner }) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Partner from "@/components/Admins/Partners/partners";
export default {
  components: {},
  props: ["partnerName"],
  data() {
    return {
      partner: "",
      partnerAdded: false,
      partnerError: false,
      isBusy: false
    };
  },
  validations: {
    partner: { required }
  },
  mounted() {
    this.partner = this.partnerName;
  },
  methods: {
    isReady() {
      if (!this.partnerAdded) {
        this.$toast.fire({
          icon: "warning",
          title: this.$t("partner.addPartnerFirst")
        });
      }
      return this.partnerAdded;
    },
    storePartner() {
      this.isBusy = true;
      this.partnerError = false;
      this.partnerAdded = false;
      const data = {
        name: this.partner
      };
      Partner.store(data)
        .then(response => {
          if (response.status !== 200 && response.status !== 201)
            throw new Error("Error");
          this.$emit("partner-added", response.data.data);
          this.partnerAdded = true;
          this.isBusy = false;
        })
        .catch(error => {
          this.$toast.fire({
            icon: "error",
            title: error.response.data.message
          });
          this.partnerError = true;
          this.isBusy = false;
        });
    }
  }
};
</script>
