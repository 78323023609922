<template>
  <div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <h5 class="font-weight-bold">
          {{ $t("partner.stepTenantConnections") }}:
        </h5>
      </div>
    </div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <p>{{ $t("partner.stepTenantConnectionsIntro") }}</p>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("partner.databaseName") }}
      </label>
      <div class="col-md-9 col-lg-6 col-xl-4">
        <input
          v-model="$v.database.database.$model"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': $v.database.database.$error }"
          :readonly="readonlyInputs"
        />
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("partner.databaseUsername") }}
      </label>
      <div class="col-md-9 col-lg-6 col-xl-4">
        <input
          v-model="$v.database.username.$model"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': $v.database.username.$error }"
          :readonly="readonlyInputs"
        />
      </div>
    </div>

    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("partner.databasePassword") }}
      </label>
      <div class="col-md-9 col-lg-6 col-xl-4">
        <div class="input-group">
          <div class="input-group-prepend" @click="copyText(password)">
            <span class="input-group-text">
              <i class="fal fa-copy cursor-pointer" />
            </span>
          </div>
          <input
            v-model="$v.database.password.$model"
            :type="[showPassword ? 'text' : 'password']"
            class="form-control pr-0"
            :class="{ 'is-invalid': $v.database.password.$error }"
            :readonly="readonlyInputs"
          />
          <div class="input-group-append" @click="togglePassword()">
            <span class="input-group-text">
              <i
                class="fal icon-lg cursor-pointer"
                :class="[showPassword ? 'fa-eye-slash' : 'fa-eye']"
              ></i>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="separator separator-dashed my-5"></div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <h5 class="font-weight-bold">
          {{ $t("partner.stepTenantConnectionsMySql") }}:
        </h5>
      </div>
    </div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <button
          class="btn btn-primary"
          :class="{ 'spinner spinner-white spinner-right': isBusyMySql }"
          :disabled="isBusyMySql || mySqlConnected"
          @click="connectMySql"
        >
          {{ $t("partner.stepTenantConnectionsAssign") }}
        </button>
      </div>
    </div>
    <div v-if="mySqlConnected" class="form-group row align-items-center mt-5">
      <div class="col-xl-3">
        <span class="svg-icon svg-icon-success svg-icon-3x">
          <inline-svg src="media/svg/icons/Navigation/Double-check.svg" />
        </span>
      </div>
      <div class="col-lg-9 col-xl-6">
        <div class="">{{ $t("partner.stepTenantConnectionsSuccess") }}</div>
      </div>
    </div>
    <div v-if="mySqlError" class="form-group row align-items-center mt-5">
      <div class="col-xl-3">
        <span class="svg-icon svg-icon-danger svg-icon-3x">
          <inline-svg src="media/svg/icons/Navigation/Close.svg" />
        </span>
      </div>
      <div class="col-lg-9 col-xl-6">
        <div class="">{{ $t("partner.stepTenantConnectionsError") }}</div>
      </div>
    </div>
    <div class="separator separator-dashed my-5"></div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import TenantConnections from "@/components/Admins/TenantConnections/tenantConnections";

export default {
  components: {},
  props: ["db", "partnerId", "uuid", "partnerData"],
  data() {
    return {
      database: {
        database: "",
        username: "",
        password: ""
      },
      mySqlConnected: false,
      mySqlError: false,
      isBusyMySql: false,
      showPassword: false
    };
  },
  validations: {
    database: {
      database: { required },
      username: { required },
      password: { required }
    }
  },
  computed: {
    readonlyInputs: function () {
      return this.mySqlConnected || this.isBusyMySql;
    }
  },
  watch: {
    db: {
      deep: true,
      handler: function () {
        this.mapProps();
      }
    }
  },
  mounted() {
    this.mapProps();
  },
  methods: {
    copyText(text) {
      this.$copyText(text);
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied")
      });
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    isReady() {
      this.$v.$touch();
      let ready = this.mySqlConnected && !this.$v.$invalid;
      if (!ready) {
        this.$toast.fire({
          icon: "warning",
          title: this.$t("partner.stepTenantConnectionsWarning")
        });
      }
      return ready;
    },
    mapProps() {
      this.database = JSON.parse(JSON.stringify(this.db));
    },
    connectMySql() {
      if (!this.validFields()) return;
      this.isBusyMySql = true;
      this.mySqlConnected = false;
      this.mySqlError = false;
      const data = {
        database: this.database.database,
        host: "localhost",
        username: this.database.username,
        password: this.database.password,
        port: "3306",
        name: "tenant",
        type: "mysql",
        tenant_id: this.partnerData.tenant.id
      };
      TenantConnections.store(data)
        .then(response => {
          if (response.status !== 200 && response.status !== 201)
            throw new Error("Error");
          this.mySqlConnected = true;
          this.isBusyMySql = false;
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response.data.message
          });
          this.mySqlError = true;
          this.isBusyMySql = false;
        });
    },
    validFields() {
      this.$v.database.$touch();
      return !this.$v.database.$invalid;
    }
  }
};
</script>
