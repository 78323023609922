import ApiService from "@/core/services/api.service";
var url = process.env.VUE_APP_API_ADMIN;
ApiService.setHeader();

const storageTypes = [
  {
    type: "ftp",
    label: "storage.ftp",
    config: {
      host: {
        type: "text",
        label: "storage.host"
      },
      port: {
        type: "text",
        label: "storage.port"
      },
      username: {
        type: "text",
        label: "storage.username"
      },
      password: {
        type: "password",
        label: "storage.password"
      },
      ssl: {
        type: "boolean",
        label: "storage.ssl"
      },
      passive: {
        type: "boolean",
        label: "storage.passive"
      },
      timeout: {
        type: "text",
        label: "storage.timeout"
      }
    }
  },
  {
    type: "sftp",
    label: "storage.sftp",
    config: {
      host: {
        type: "text",
        label: "storage.host"
      },
      port: {
        type: "text",
        label: "storage.port"
      },
      username: {
        type: "text",
        label: "storage.username"
      },
      password: {
        type: "password",
        label: "storage.password"
      },
      ssl: {
        type: "boolean",
        label: "storage.ssl"
      },
      passive: {
        type: "boolean",
        label: "storage.passive"
      },
      timeout: {
        type: "text",
        label: "storage.timeout"
      }
    }
  },
  {
    type: "local",
    label: "storage.local",
    config: {
      root: {
        type: "text",
        label: "storage.root"
      }
    }
  }
];

class TenantStorage {
  get(id) {
    return ApiService.get(url, "tenant_storages/" + id);
  }
  getAll(params) {
    var options = "?page=" + params.page + "&perPage=" + params.size;
    return ApiService.get(url, "tenant_storages" + options);
  }
  store(data) {
    return ApiService.post(url + "/tenant_storages", data);
  }
  update(id, data) {
    return ApiService.put(url + "/tenant_storages/" + id, data);
  }
  delete(id) {
    return ApiService.delete(url + "/tenant_storages/" + id);
  }
  getTypes() {
    return storageTypes;
  }
}
export default new TenantStorage();
