import ApiService from "@/core/services/api.service";

ApiService.setHeader();
let url = process.env.VUE_APP_API_ADMIN;

class Tenants {
  initialize(id) {
    return ApiService.post(url + `/tenants/${id}/initialize`, {});
  }
}

export default new Tenants();
