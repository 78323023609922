<template>
  <div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <h5 class="font-weight-bold">
          {{ $t("partner.stepCreateDatabase") }}:
        </h5>
      </div>
    </div>
    <div class="row">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <p v-html="$t('partner.stepCreateDatabaseIntro')" />
        <p>
          {{ $t("partner.stepCreateDatabaseLink") }}
          <a :href="SERVER_CON" target="_blank"
            >{{ SERVER_CON }} <i class="fal fa-arrow-up-right-from-square"
          /></a>
        </p>
      </div>
    </div>
    <div class="row mt-5">
      <label class="col-xl-3"></label>
      <div class="col-lg-9 col-xl-6">
        <h5 class="font-weight-bold">
          {{ $t("partner.stepCreateDatabaseLoginData") }}:
        </h5>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("partner.databaseName") }}
      </label>
      <div class="col-md-9 col-lg-6 col-xl-4">
        <input
          v-model="$v.database.database.$model"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': $v.database.database.$error }"
        />
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("partner.databaseUsername") }}
      </label>
      <div class="col-md-9 col-lg-6 col-xl-4">
        <input
          v-model="$v.database.username.$model"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': $v.database.username.$error }"
        />
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("partner.databasePassword") }}
      </label>
      <div class="col-md-9 col-lg-6 col-xl-4">
        <div class="input-group">
          <div class="input-group-prepend" @click="copyText(password)">
            <span class="input-group-text">
              <i class="fal fa-copy cursor-pointer" />
            </span>
          </div>
          <input
            v-model="$v.database.password.$model"
            :type="[showPassword ? 'text' : 'password']"
            class="form-control pr-0"
            :class="{ 'is-invalid': $v.database.password.$error }"
          />
          <div class="input-group-append" @click="togglePassword()">
            <span class="input-group-text">
              <i
                class="fal icon-lg cursor-pointer"
                :class="[showPassword ? 'fa-eye-slash' : 'fa-eye']"
              ></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row align-items-center">
      <label class="col-xl-3 col-lg-3 col-form-label">
        {{ $t("partner.partnerName") }}
      </label>
      <div class="col-md-9 col-lg-6 col-xl-4">
        <input
          v-model.trim="$v.partnerName.$model"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': $v.partnerName.$error }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      SERVER_CON: process.env.VUE_APP_SERVER_CON,
      bus: bus,
      database: {
        database: "",
        username: "",
        password: ""
      },
      partnerName: "",
      showPassword: false
    };
  },
  validations: {
    database: {
      database: { required },
      username: { required },
      password: { required }
    },
    partnerName: { required }
  },
  mounted() {},
  methods: {
    copyText(text) {
      this.$copyText(text);
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied")
      });
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    isReady() {
      this.$v.$touch();
      let ready = !this.$v.$invalid;
      if (ready) {
        this.$emit("set-params", {
          database: this.database,
          partnerName: this.partnerName
        });
      }
      return ready;
    }
  }
};
</script>
