<template>
  <div class="offset-md-2 col-md-8 card card-custom">
    <div class="card-body position-relative">
      <div class="text-right">
        <button
          class="btn btn-secondary"
          @click="$router.push({ name: 'adminPartners' })"
        >
          {{ $t("partner.cancelCreate") }}
        </button>
      </div>
      <div
        id="kt_wizard_partners"
        class="wizard wizard-1"
        data-wizard-state="step-content"
        data-wizard-clickable="false"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav border-bottom">
          <div
            id="wizard-steps"
            ref="wizard-steps"
            class="wizard-steps py-8 p-lg-10 overflow-x-auto justify-content-start"
          >
            <div
              v-for="(step, i) in steps"
              :id="'step-' + (i + 1)"
              :key="i"
              class="wizard-step"
              data-wizard-type="step"
            >
              <div class="wizard-label">
                <i class="wizard-icon" :class="step.icon"></i>
                <h3 class="wizard-title">
                  {{ step.label }}
                </h3>
              </div>
              <i
                v-if="i < steps.length - 1"
                class="wizard-arrow fal fa-chevron-right"
              ></i>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav-->
        <v-progress-linear v-if="isBusy" indeterminate />
        <!--begin: Wizard Body-->
        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
          <div class="col-xl-12 col-xxl-8">
            <div data-wizard-type="step-content">
              <StepCreateDatabase ref="step-1" @set-params="setParamsStep1" />
            </div>
            <div data-wizard-type="step-content">
              <StepAddPartnerEntry
                ref="step-2"
                :key="addPartnerKey"
                :partner-name="partnerName"
                @partner-added="setPartnerStep2"
              />
            </div>
            <div data-wizard-type="step-content">
              <StepTenantStorage ref="step-3" :uuid="uuid" />
            </div>
            <div data-wizard-type="step-content">
              <StepTenantConnections
                ref="step-4"
                :db="databaseConfig"
                :partner-id="partnerId"
                :partner-data="partnerData"
                :uuid="uuid"
              />
            </div>
            <div data-wizard-type="step-content">
              <StepFtpUser
                ref="step-5"
                :uuid="uuid"
                :partner-data="partnerData"
              />
            </div>
            <div data-wizard-type="step-content">
              <StepInitializeTenant
                ref="step-6"
                :partner-id="partnerId"
                :partner-data="partnerData"
                @partner-updated="updatePartnerStep7"
              />
            </div>
          </div>
        </div>
        <!--begin: Wizard Actions -->
        <div
          class="d-flex justify-content-between border-top pt-10 wizard-actions"
        >
          <div class="mr-2">
            <button
              class="btn btn-light-primary font-weight-bold text-uppercase px-sm-9 py-sm-4 px-3 py-2"
              data-wizard-type="action-prev"
              :disabled="isBusy"
              @click="previousStep"
            >
              {{ $t("general.previousStep") }}
            </button>
          </div>
          <div>
            <button
              class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
              data-wizard-type="action-submit"
              :disabled="isBusy"
              @click="finish"
            >
              {{ $t("partner.wizardFinish") }}
            </button>
            <button
              class="btn btn-primary font-weight-bold text-uppercase px-sm-9 py-sm-4 px-3 py-2"
              data-wizard-type="action-next"
              :disabled="isBusy"
              @click="nextStep"
            >
              <span>{{ $t("general.nextStep") }}</span>
            </button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </div>
    </div>
  </div>
</template>

<script>
import KTWizard from "@/assets/js/components/wizard";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { bus } from "@/main";
import $ from "jquery";

// STEPS
import StepCreateDatabase from "@/components/Admins/Partners/Steps/StepCreateDatabase";
import StepAddPartnerEntry from "@/components/Admins/Partners/Steps/StepAddPartnerEntry";
import StepTenantStorage from "@/components/Admins/Partners/Steps/StepTenantStorage";
import StepTenantConnections from "@/components/Admins/Partners/Steps/StepTenantConnections";
import StepFtpUser from "@/components/Admins/Partners/Steps/StepFtpUser";
import StepInitializeTenant from "@/components/Admins/Partners/Steps/StepInitializeTenant";

export default {
  components: {
    StepCreateDatabase, // Step 1
    StepAddPartnerEntry, // Step 2
    StepTenantStorage, // Step 3
    StepTenantConnections, // Step 4
    StepFtpUser, // Step 5
    StepInitializeTenant // Step 6
  },
  data() {
    return {
      wizard: null,
      steps: [
        {
          label: this.$t("partner.stepCreateDatabase"),
          icon: "fal fa-rocket-launch"
        },
        {
          label: this.$t("partner.stepAddPartnerEntry"),
          icon: "fal fa-user-plus"
        },
        {
          label: this.$t("partner.stepTenantStorage"),
          icon: "fal fa-box-open-full"
        },
        {
          label: this.$t("partner.stepTenantConnections"),
          icon: "fal fa-cloud-check"
        },
        { label: this.$t("partner.stepFtpUser"), icon: "fal fa-key" },
        {
          label: this.$t("partner.stepInitializeTenant"),
          icon: "fal fa-user-check"
        }
      ],
      activeStep: 1,
      isBusy: false,
      enableNext: false,
      // Step 1
      dbConfig: {
        database: "",
        username: "",
        password: ""
      },
      partnerName: "",
      // Step 2
      partnerAdded: false,
      partner: {},
      // Component keys
      addPartnerKey: 1,
      activateQueuesKey: 1
    };
  },
  computed: {
    uuid: function () {
      return this.partner.uuid;
    },
    partnerId: function () {
      return this.partner.id;
    },
    partnerData: function () {
      return this.partner;
    },
    databaseConfig: function () {
      return this.dbConfig;
    }
  },
  mounted() {
    this.initializeWizard();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.adminPartners"),
        route: "/admin/partners"
      },
      {
        title: this.$t("partner.partnerCreate"),
        route: "/admin/partners/create"
      }
    ]);
    bus.$on("next-step", this.onNextStep);
  },
  destroyed() {
    bus.$off("next-step", this.onNextStep);
  },
  methods: {
    onNextStep(value) {
      this.enableNext = value;
    },
    initializeWizard() {
      let me = this;
      this.$nextTick().then(() => {
        me.wizard = new KTWizard("kt_wizard_partners", {
          clickableSteps: false
        });
      });
    },
    nextStep() {
      this.wizard.stop();
      let step = this.wizard.getStep();
      let goNext = this.$refs["step-" + step].isReady();
      if (!goNext) return;
      switch (step) {
        case 6:
          this.activateQueuesKey++;
          break;
      }
      this.scrollToStep(step + 1);
      this.wizard.resume();
    },
    previousStep() {
      this.wizard.stop();
      let step = this.wizard.getStep();
      this.scrollToStep(step - 1);
      this.wizard.resume();
    },
    scrollToStep(step) {
      let el = $("#step-" + step)[0];
      let elLeft = el.offsetLeft;
      let container = $("#wizard-steps");
      container.animate({ scrollLeft: elLeft }, 300);
    },
    setParamsStep1(payload) {
      this.dbConfig = payload.database;
      this.partnerName = payload.partnerName;
      if (!this.partnerAdded) this.addPartnerKey++;
    },
    setPartnerStep2(payload) {
      this.partner = payload;
      this.partnerAdded = true;
    },
    updatePartnerStep7(payload) {
      if (payload.id === this.partner.id) this.partner = payload;
    },
    finish() {
      let step = this.wizard.getStep();
      let finish = this.$refs["step-" + step].isReady();
      if (finish)
        this.$router.push({
          name: "adminPartnersDetails",
          params: { id: this.partnerId }
        });
      bus.$emit("reloadHeaderFilterPartner");
    }
  }
};
</script>

<style lang="scss">
@import "~@/assets/sass/pages/wizard/wizard-1";

#wizard-steps {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.wizard-actions {
  position: sticky;
  background: #fff;
  padding-bottom: 20px;
  bottom: 0;
}
.wizard-icon {
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wizard-title {
  height: 34px;
}
</style>
